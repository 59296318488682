.news-author {
    display: flex;
    flex-direction: column;
    text-decoration: none;
}

.news-author-left img {
    width: 100%;
}

.news-author-right {
    margin-top: .25rem;
    margin-left: 0;
}

@media (min-width: 560px) {
    .news-author {
        display: flex;
        flex-direction: row;
    }

    .news-author-left img {
        width: 150px;
    }

    .news-author-right {
        margin-top: 0;
        margin-left: 2ch;
    }
}