.societybasicinfo-basic {
    padding: 5%;
    background: var(--grey);
    color: black;
}

.societybasicinfo-basic a + a {
    margin-left: 1ch;
}

.societybasicinfo-nature {
    padding-inline: .25rem;
}

.societybasicinfo-wcrcontact + .societybasicinfo-wcrcontact::before {
    content: ", ";
}

@media (min-width: 800px) {

    .societybasicinfo-basic {
        padding: 3ch;
    }
}