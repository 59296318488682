/* Trigger value - 1160px */

.navbar-container {
    background-color: var(--primary);
    height: 112px;
    box-shadow: 0 8px 10px -6px rgb(49, 49, 49);
    z-index: 1;
    position: sticky;
    top: 0px;
    padding-inline: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

.navbar-title {
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: white;
}

.navbar-title:hover, .navbar-title:visited {
  color: white;
}

.navbar-title-full {
  display: inline;
}

.navbar-univ {
  padding: 0;

  /* If using Trajan font then use the code below to adjust spacings. */
  font-family: "Trajan", "Open Sans", "San Serif";
  font-variant: small-caps;
  line-height: 25px;
  word-spacing: -.6ch;
  margin-inline: 0;
  margin-top: .5rem;
  margin-bottom: -.5rem;

  /* Otherwise, use the following code. */
  /* margin: 0; */
}

.navbar-wcr {
  margin: 0;
  padding: 0;
}

.navbar-title-short {
  display: none;
}

.navbar-logo {
  margin-right: 1.75rem;
  height: 56px;
}

.navbar-menu {
  display: flex;
  flex-direction: row;
  list-style: none;
  text-align: center;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  height: 100%;
}

.navbar-item {
  display: flex;
  align-items: center;
  height: 100%;
  margin-inline: .25rem;
}

.navbar-item > button {
  padding: .5rem;
  background-color: var(--blue);
  border: none;
  border-radius: .2rem;
  font-size: .8rem;
}

.navbar-links {
  text-decoration: none;
  padding: 0.5rem 0.5rem;
  font-size: 0.8rem;
  color: var(--lightgrey);
}

.navbar-links:hover {
  transition: all 0.2s ease-out;
  color: var(--blue);
}

.navbar-menu-icon {
  display: none;
}

@media screen and (max-width: 1160px) {
  .navbar-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: 0;
    position: absolute;
    top: 112px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    height: auto;
  }

  .navbar-menu.active {
    background: black;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .navbar-item {
    flex-direction: column;
  }

  .navbar-links {
    text-align: center;
    padding: .8rem 0;
    width: 100%;
    display: table;
    font-size: 1rem;
  }

  .navbar-links:hover {
    background-color: var(--primary);
    color: white;
  }

  .navbar-menu-icon {
    display: block;
    font-size: 1.8rem;
    cursor: pointer;
    color: white;
  }

  .navbar-item > button {
    margin-top: .5rem;
    margin-bottom: .5rem;
    font-size: inherit;
  }
}

@media (max-width: 472px) {
  .navbar-title-full {
    display: none;
  }
  
  .navbar-title-short {
    display: block;
    padding: 1.5ch 0;
  }
}