.eventbasicinfo-basic {
    padding: 5%;
    background: var(--grey);
    color: black;
}

.eventbasicinfo-basic a + a {
    margin-left: 1ch;
}

.eventbasicinfo-nature {
    font-size: .9rem;
    padding-inline: .25rem;
}

.eventbasicinfo-nature + .eventbasicinfo-nature {
    margin-left: .25rem;
}

.eventbasicinfo-register {
    width: 100%;
    background-color: var(--primary);
    color: white;
    margin-bottom: .5rem;
    cursor: pointer;
}

.eventbasicinfo-eventlink {
    width: 100%;
    background-color: var(--secondary);
    color: var(--primary);
    margin-bottom: .5rem;
    cursor: pointer;
}

@media (min-width: 800px) {

    .eventbasicinfo-basic {
        padding: 3ch;
    }
}