.quicklinks {
    display: flex;
    flex-direction: column;
    text-decoration: none;

    /* this is var(--secondary) with alpha 0.3 */
    background-color: rgba(251, 189, 26, 0.3);
}

.quicklinks:hover {
    /* this is var(--secondary) with alpha 0.7 */
    background-color: rgba(251, 189, 26, 0.7);
    color: black;
    transition: background-color 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.quicklinks-des {
    /* this is var(--secondary) with alpha 0.3 */
    background-color: rgba(251, 189, 26, 0.3);
    padding: .25rem .5rem;
}

.quicklinks-link {
    padding: .25rem .5rem;
    text-decoration: underline;
}