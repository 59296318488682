.whatson-filters {
    margin-top: 2rem;
}

.whatson-filters div {
    border-color: black;
    border-style: solid;
    border-width: .5px;
    padding: .5rem;
}
