.filtermenu-container {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.filtermenu-item {
    margin-bottom: 1.25rem;
    padding: 0;
}

.filtermenu-item + .filtermenu-item {
    margin-left: .25rem;
}

.filtermenu-item input[type=checkbox] {
    display: none;
}

.filtermenu-item label {
    border: solid;
    border-width: 1px;
    padding: .25rem;
    opacity: .4;
}

.filtermenu-item input[type=checkbox]:checked + label {
    opacity: 1;
}